.poems {
  grid-column: 4;
  display: grid;
  grid-gap: 20px;
}

.poems > .p {
  background-color: var(--font-primary);
  border-radius: 0.25rem;
  padding: 1rem;
}

.poems > .p > .text {
  font-weight: 600;
}

.poems > .p > .text > p {
  margin-bottom: 5px;
}

.poems > .p > .author {
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 0.5rem;
  text-align: right;
}

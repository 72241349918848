/* color palette https://www.colourlovers.com/palette/1397816/avilluk */

:root {
  --primary: #1B8798;
  --secondary: #D61C59;
  --tertiary: #E7D84B;
  --font-primary: #EFEAC5;
  --font-secondary: #360745;
}

html, body, * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-family: 'Bubbler One', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
    149deg,
    var(--primary) 0%,
    var(--secondary) 100%
  );
  background-repeat: no-repeat;
  font-size: 1.4rem;
}

.button {
  background-color: var(--tertiary);
  color: var(--font-secondary);
  font-weight: bold;
  border-radius: 0.25rem;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0.5rem 1.5rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.button + .button {
  margin-left: 20px;
}

.create-poem {
  position: fixed;
  top: 0px;
  right: 0px;
  margin: 20px;
}

.create-poem i {
  margin-right: 10px;
}

.create-poem .button {
  font-size: 1.1rem;
}

.button:hover {
  cursor: pointer;
}

.button[disabled] {
  cursor: not-allowed;
}

.wrapper {
  padding: 3rem;
  display: grid;
  grid-template-areas:
    'header header'
    'poem instructions'
    'poemList poemList';
  grid-gap: 20px;
}

.title {
  font-family: 'Spirax', cursive;
  grid-area: header;
}

.title > h1 {
  font-size: 5rem;
  display: inline-block;
  cursor: pointer;
}

.instructions {
  grid-area: instructions;
}

.poem-list {
  grid-area: poemList;
  display: grid;
  grid-template-columns: 20px 1fr 1fr minmax(350px, 2fr);
}

.tooltip {
  position: absolute;
  top: 110%;
  right: 90%;
  padding: 10px;
  background-color: var(--primary);
  color: var(--font-primary);
  font-size: 1.2em;
  border: 1px solid black;
  min-width: 250px;
}

.hide {
  display: none;
}

@media (max-width: 900px) {
  .wrapper {
    grid-template-areas:
      'header'
      'poem'
      'instructions'
      'poemList';
    padding: 1rem;
  }
  .title > h1 {
    font-size: 3rem;
  }
}

@media (max-width: 580px) {
  .create-poem {
    position: relative;
    margin: 10px;
    font-size: 1.1rem;
  }

  .poem-list {
    grid-template-columns: 10px 0px 0px 1fr;
  }

  .tooltip {
    right: unset;
  }
}

@media (max-width: 400px) {
  .wrapper {
    padding: 0.7rem;
  }
  .title {
    font-size: 2rem;
  }
}
